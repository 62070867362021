<template>
  <div>
    <b-row>
      <b-col md="12" style="display: flex; gap:10px;">
        <export-button
          :filter="exportFilter"
          url="/transaction/agent-report/export"
          :disabled="loadindData"
          file-title="Agents_Report"
        />
        <b-button v-b-modal.modal-hierachy v-if="width<768">
          <feather-icon icon="GitPullRequestIcon" />
        </b-button>
      </b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col md="9">
        <b-card no-body class="p-1">
          <b-card-title class="mb-2">{{
          $t("tabs.reportAgentsByBrands")
          }}</b-card-title>
          <b-row>
            <b-col md="4">
              <b-form-group
                label="Date from"
                label-for="datefrom"
                rules="required"
              >
                <template #label>{{ $t("labels.datefrom") }}</template>
                <flat-pickr
                  v-model="dateFrom"
                  class="form-control"
                  :config="{
                              altInput: true,
                              altFormat: 'd-m-Y - H:i',
                              enableTime: true,
                              dateFormat: 'Y-m-d:H:i',
                              time_24hr: true,
                              wrap : true,
                              plugins: [
                                 new yearDropdownPlugin({
                                   yearStart: 100,
                                   yearEnd: 0
                                })
                               ],
                            }"
                />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group label="Date To" label-for="dateto" rules="required">
                <template #label>{{ $t("labels.dateto") }}</template>
                <flat-pickr
                  v-model="dateTo"
                  class="form-control"
                  :config="{
                              altInput: true,
                              altFormat: 'd-m-Y - H:i',
                              enableTime: true,
                              dateFormat: 'Y-m-d:H:i',
                              time_24hr: true,
                              wrap : true,
                              plugins: [
                                 new yearDropdownPlugin({
                                   yearStart: 100,
                                   yearEnd: 0
                                })
                               ],
                              }"
                />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                :label="$t('labels.search_historic')"
                label-for="isHistorical"
              >
                <b-form-checkbox v-model="isHistorical" @change="getTotals" />
              </b-form-group>
            </b-col>
            <!-- <b-col md="4">
                <b-form-group
                  label="Currency"
                  label-for="currency"
                >
                  <template #label>{{$t('labels.currency')}}</template>
                  <v-select
                    v-model="currency"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="currenciesOptions"
                    :reduce="(option) => option.abbreviation"
                    @input="currencyChanged"
                    :clearable="false"
                    label="name"
                  />
                </b-form-group>
                </b-col> -->
          </b-row>
          <b-row>
            <b-col md="12" class="d-flex justify-content-between w-100">
              <!-- <b-button 
                variant="primary"
                @click="getTotalLazy" >{{$t('buttons.search')}}</b-button> -->

              <!--              <div v-if="dataTable.length > 0">-->
              <!--                <b-card-text class="mb-0 mr-1"> Details </b-card-text>-->
              <!--                <b-form-checkbox v-model="detailsDataChecked" class="custom-control-primary" name="check-button"-->
              <!--                  switch -->
              <!--                  disabled-->
              <!--                />-->
              <!--              </div>-->
            </b-col>
          </b-row>
          <br />
          <b-overlay :show="loadindData">
            <!--            <div v-if="dataTable.length > 0">-->
            <b-table-simple hover small caption-top responsive>
              <b-thead head-variant="light">
                <b-tr>
                  <b-th>{{ $t("category") }}</b-th>
                  <b-th>{{ $t("bet") }}</b-th>
                  <b-th>{{ $t("betted_amount") }}</b-th>
                  <b-th>{{ $t("win") }}</b-th>
                  <b-th>{{ $t("net_win") }}</b-th>
                  <b-th>{{ $t("commission") }}</b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr
                  :variant="tr._rowVariant"
                  v-for="(tr, indexTr) in dataTable"
                  :key="indexTr"
                >
                  <b-td class="text-noWrap">{{ tr.Category }}</b-td>
                  <b-td class="text-noWrap">{{ tr.Bets }}</b-td>
                  <b-td
                    class="text-noWrap"
                    >{{ tr.Betted | currency({symbol: ""})  }}</b-td
                  >
                  <b-td
                    class="text-noWrap"
                    >{{ tr.Won | currency({symbol: ""})  }}</b-td
                  >
                  <b-td
                    class="text-noWrap"
                    >{{ tr.NetWin | currency({symbol: ""})  }}</b-td
                  >
                  <b-td
                    class="text-noWrap"
                    >{{ tr.Commissions | currency({symbol: ""})  }}</b-td
                  >
                </b-tr>
                <b-tr v-if="dataTable.length < 1">
                  <b-th colspan="6" class="text-center">
                    <div class="m-2">{{ $t('no_data') }}</div>
                  </b-th>
                </b-tr>
              </b-tbody>
              
              <br/>
              
              <b-tbody>
                <b-tr>
                  <b-td>{{ $t('total') }}:</b-td>
                  <b-td
                  >{{ dataTotal.totals.totalBets  }}
                  </b-td>
                  <b-td
                  >{{ dataTotal.totals.totalBetsAmount | currency({symbol: ""})  }}
                  </b-td>
                  <b-td
                  >{{ dataTotal.totals.totalWinsAmount | currency({symbol: ""})  }}
                  </b-td>
                  <b-td
                  >{{ dataTotal.totals.totalNetIncome | currency({symbol: ""})  }}
                  </b-td>
                  <b-td
                  >{{ dataTotal.totals.totalCommission | currency({symbol: ""})  }}
                  </b-td>
                </b-tr>

                <b-tr >
                  <b-th colspan="5">{{ $t('total_recharges_and_withdrawals') }}:</b-th>
                  <b-td
                  >{{ dataTotal.totals.totalPayment | currency({symbol: ""})  }}
                  </b-td>
                </b-tr>

                <b-tr>
                  <b-th colspan="2">{{ $t('sales_chip') }}:</b-th>
                  <b-td
                  >{{ dataTotal.totals.recharges | currency({symbol: ""})  }}
                  </b-td>
                  <b-td
                  >{{ dataTotal.totals.retirement | currency({symbol: ""})  }}
                  </b-td>
                  <b-td colspan="2"
                  >{{ dataTotal.totals.diff | currency({symbol: ""})  }}
                  </b-td>
                </b-tr>
                
                <b-tr>
                  <b-th colspan="2">{{ $t('labels.payment_methods') }}:</b-th>
                  <b-td
                  >{{ dataTotal.totals.paymentMethodCreditsAmount | currency({symbol: ""})  }}
                  </b-td>
                  <b-td
                  >{{ dataTotal.totals.paymentMethodDebitsAmount | currency({symbol: ""})  }}
                  </b-td> 
                  <b-td colspan="2"
                  >{{ dataTotal.totals.paymentMethodNetIncome | currency({symbol: ""})  }}
                  </b-td>
                </b-tr>

                <b-tr>
                  <b-th colspan="4">Tabs on Panels:</b-th>
                  <b-td
                  >{{ dataTotal.totals.agents | currency({symbol: ""})  }}
                  </b-td>
                  <b-td></b-td>
                </b-tr>

                <b-tr>
                  <b-th colspan="4">{{ $t('total_players') }}:</b-th>
                  <b-td>
                    <p v-if="totalPlayers !== null">
                      {{ totalPlayers | currency({symbol: ""})  }}
                    </p>
                    <b-overlay
                        v-else
                        :show="loadindDataBalancePlayer"
                        rounded
                        opacity="0.6"
                        spinner-small
                        spinner-variant="primary"
                        class="d-inline-block"
                    >
                      <b-button
                          variant="primary"
                          @click="fetchPlayerBalances"
                          size="sm"
                      >
                        {{ $t('buttons.search') }}
                      </b-button>
                    </b-overlay>
                  </b-td>
                  <b-td></b-td>
                </b-tr>
              </b-tbody>

            </b-table-simple>

            <!--            </div>-->
          </b-overlay>
        </b-card>
      </b-col>

      <b-modal
        id="modal-hierachy"
        hide-footer
        title="Listado de agentes"
        v-if="width<768"
      >
        <HierarchyUsers
          @selectUser="selectUserHierarchy"
          ref="HerarchiComponent"
        />
      </b-modal>

      <b-col md="3" v-else>
        <b-card no-body class="p-0 py-1">
          <HierarchyUsers @selectUser="selectUserHierarchy" />
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BCollapse,
  BForm,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BRow,
  BCol,
  BButton,
  BAlert,
  BFormCheckbox,
  BCardText,
  BOverlay,
  BCardTitle,
  BTableSimple,
  BThead,
  BTr,
  BTh,
  BTfoot,
  BTd,
  BTbody,
} from "bootstrap-vue";
import { BTable } from "bootstrap-vue";
import store from "@/store";
import { ref, onUnmounted } from "@vue/composition-api";
import "vue-tree-halower/dist/halower-tree.min.css";
import { VTree, VSelectTree } from "vue-tree-halower";
import userStoreModule from "../../../apps/user/userStoreModule";
// import clientStoreModule from "../../../pages/clients/clientStoreModule";
import agentsReportStoreModule from "../reportAgentsStoreModule";
import whitelabelStoreModule from "../../../pages/whitelabel/whitelabelStoreModule";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import moment from "moment";
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";
import HierarchyUsers from "@core/components/HierarchyUsers/HierarchyUsers.vue";
import clientStoreModule from "@/views/pages/clients/clientStoreModule";
import axios from "@/libs/axios";
import ExportButton from "@/views/pages/export/ExportButton.vue";
import { axiosErrorHandle } from '@core/utils/errorHandler'
import {translatableText} from "@core/utils/utils";
import yearDropdownPlugin from "@/helpers/year_flatpickr_plugin";
export default {
  components: {
      ExportButton,
    BTable,
    BCard,
    BRow,
    BCol,
    BCardHeader,
    BCardBody,
    BCollapse,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BTableSimple,
    VSelectTree,
    BThead,
    vSelect,
    VTree,
    BTr,
    BTh,
    BForm,
    BOverlay,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BButton,
    ToastificationContent,
    BAlert,
    BTfoot,
    BTd,
    BTbody,
    flatPickr,
    HierarchyUsers,
  },
  data() {
    return {
      totalPlayers: null,
      dateFrom: moment().format("YYYY-MM-DD:00:00:00"),
      dateTo: moment().format("YYYY-MM-DD:23:59:59"),
      currency: "",
      currenciesOptions: [],
      initSelected: [],
      dataGamesFullDetails: [],
      dataTable: [],
      rangeDate: "",
      idUserSelect: "",
      detailsDataChecked: true,
      loadindData: false,
      typeUser: '',
      loadindDataBalancePlayer: false,
      dataTotal: {
        totals: {
          recharges: 0,
          retirement: 0,
          diff: 0,
          agents: 0,

          totalBets: 0,
          totalBetsAmount: 0,
          totalCommission: 0,
          totalNetIncome: 0,
          totalPayment: 0,
          totalWins: 0,
          totalWinsAmount: 0,
          
          paymentMethodCreditsAmount:0,
          paymentMethodDebitsAmount :0,
          paymentMethodNetIncome: 0
        },
      },
      timeOut: null,
      width: 0,
    };
  },
  watch: {
    detailsDataChecked(val) {
      if (val) this.dataTable = this.dataGamesFullDetails;
      else this.dataTable = this.dataGames;
    },
    dateFrom() {
      this.getTotalLazy();
    },
    dateTo() {
      this.getTotalLazy();
    },
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = "app-user";
    const CLIENT_APP_STORE_MODULE_NAME = "app-client";
    const WL_APP_STORE_MODULE_NAME = "app-whitelabel";
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule);
    if (!store.hasModule(CLIENT_APP_STORE_MODULE_NAME))
      store.registerModule(CLIENT_APP_STORE_MODULE_NAME, clientStoreModule);
    if (!store.hasModule(WL_APP_STORE_MODULE_NAME))
      store.registerModule(WL_APP_STORE_MODULE_NAME, whitelabelStoreModule);
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
      if (store.hasModule(CLIENT_APP_STORE_MODULE_NAME))
        store.unregisterModule(CLIENT_APP_STORE_MODULE_NAME);
    });
    const currencies = ref([]);
    const clientCurrencies = ref([]);
    const users = ref([]);
    const treeData = ref([]);
    const previosNode = ref("");
    const previousNodeFlag = ref(false);
    const selection = ref([]);
    const usersForReport = ref([]);
    const dataGames = ref([]);
    const brands = ref([]);
    const isHistorical = ref(false);
    return {
      dataGames,
      brands,
      treeData,
      users,
      usersForReport,
      currencies,
      clientCurrencies,
      selection,
      previousNodeFlag,
      previosNode,
      isHistorical
    };
  },
  computed: {
    currencySelectNavbar() {
      return this.$store.state.whitelabelCurrencyNabvar.currency;
    },
    whitelabelSelectNavbar() {
      return this.$store.state.whitelabelCurrencyNabvar.whitelabel;
    },
    exportFilter(){
      return {
        datein: this.dateFrom,
        dateout: this.dateTo,
        id: this.idUserSelect,
        currency: this.currencySelectNavbar,
        whitelabel: this.whitelabelSelectNavbar?._id,
        isHistorical: this.isHistorical
      }
    }
  },
  methods: {
    yearDropdownPlugin,
    async fetchPlayerBalances() {
      try {
        this.loadindDataBalancePlayer = true;
        const payload = {
          id: this.idUserSelect,
          currency: this.currencySelectNavbar,
          whitelabel: this.whitelabelSelectNavbar._id,
        };
        const { data } = await store.dispatch(
          "app-client/fetchPlayerBalances",
          payload
        );
        this.totalPlayers = data;
      } catch (error) {
        console.error(error);
      } finally {
        this.loadindDataBalancePlayer = false;
      }
    },
    async exportsData() {
      const payload = {
        datein: this.dateFrom,
        dateout: this.dateTo,
        id: this.idUserSelect,
        currency: this.currencySelectNavbar,
        whitelabel: this.whitelabelSelectNavbar._id,
        exportExcel: false,
      };
      const { data } = await store.dispatch(
        "app-client/fetchAgentsByBrandsReport",
        payload
      );
    },
    async getTotals() {
      this.brands = [];
      this.dataGames = [];
      this.dataGamesFullDetails = [];
      this.validateData();
      try {
        if (this.idUserSelect == "") {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `${this.$t('Alert')}!`,
              icon: "AlertTriangleIcon",
              text: `${this.$t('select_user_tree')}`,
              variant: "warning",
            },
          });
          return false;
        }
        this.loadindData = true;
        this.totalPlayers = null;
        const payload = {
          datein: this.dateFrom,
          dateout: this.dateTo,
          id: this.idUserSelect,
          currency: this.currencySelectNavbar,
          whitelabel: this.whitelabelSelectNavbar._id,
          isHistorical: this.isHistorical
        };
        const { data } = await axios.get(`/transaction/agent-report`, {params: payload})

        console.log("--- DATA ENTRANTEEEEEE: ", data)

        // this.typeUser = data.typeUser
        //this.exportsData();

        this.dataTotal.totals = {
          retirement : data.secondLine.debitsAmmount,
          recharges : data.secondLine.creditsAmmount,
          diff : data.secondLine.netIncome,
          agents : data.secondLine.tabsOnPannel,

          totalBets: data.totals.totalBets,
          totalBetsAmount: data.totals.totalBetsAmmount,
          totalCommission: data.totals.totalCommission,
          totalNetIncome: data.totals.totalNetIncome,
          totalPayment: data.totals.totalPayment,
          totalWins: data.totals.totalWins,
          totalWinsAmount: data.totals.totalWinsAmmount,

          paymentMethodCreditsAmount: data.paymentMethod.creditsAmmount,
          paymentMethodDebitsAmount:data.paymentMethod.debitsAmmount,
          paymentMethodNetIncome:data.paymentMethod.netIncome,
        }

        this.brands = data.principal;
        for (const brand of this.brands) {
          var gameAux = {
            Category: brand.brandId,
            Bets: brand.bets,
            Betted: brand.betsAmmount,
            Won: brand.winsAmmount,
            NetWin: brand.netIncome,
            Commissions: brand.commission,
            _rowVariant: "",
          };
          this.dataGames.push(gameAux);
          this.dataGamesFullDetails.push(gameAux);

          // const productsLenght = brand.products.length;
          // for (var i = 0; i < productsLenght; i++) {
          //   var gameAux = {
          //     Category: brand.products[i].productId,
          //     Bets: brand.products[i].bets,
          //     Betted: brand.products[i].betsAmmount,
          //     Won: brand.products[i].winsAmount,
          //     NetWin: brand.products[i].netIncome,
          //     Commissions: brand.products[i].commission,
          //     _rowVariant: "",
          //   };
          //   this.dataGames.push(gameAux);
          //   this.dataGamesFullDetails.push(gameAux);
          // }
        }
        if (this.detailsDataChecked) this.dataTable = this.dataGamesFullDetails;
        else this.dataTable = this.dataGames;
      } catch (error) {
        this.resetData()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error!",
            icon: "AlertTriangleIcon",
            text:  await translatableText({text: axiosErrorHandle(error)}),
            variant: "danger",
          },
        });
      } finally {
        this.loadindData = false;
      }
    },
    resetData(){
      this.dataTable = []
      this.dataTotal.totals = {
          retirement : '',
          recharges : '',
          diff : '',
          agents : ''
      }
    },
    validateData() {
      if (this.dateTo < this.dateFrom) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error!",
            icon: "XIcon",
            text: this.$t('review_dates'),
            variant: "danger",
          },
        });
      }
    },
    selectUserHierarchy({ id }) {
      this.idUserSelect = id;
      this.getTotalLazy();
    },
    getTotalLazy() {
      if (this.timeOut) clearTimeout(this.timeOut);
      this.timeOut = setTimeout(() => {
        this.getTotals();
      }, 500);
    },
  },
  created() {
    this.idUserSelect = JSON.parse(localStorage.getItem("userData"))._id;

    this.width = window.innerWidth;
    window.addEventListener('resize', () => {
      this.width = window.innerWidth;
    });
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";
.tree-block {
  float: left;
  width: 33%;
  padding: 10px;
  box-sizing: border-box;
  border: 1px dotted #ccccdd;
  overflow: auto;
  height: 300px;
}
.selected {
  padding: 6px 8px;
  outline: none;
  border-radius: 6px;
  background-color: #efb810;
  border: 1px solid rgb(226, 225, 225);
  color: rgb(117, 117, 117);
}
</style>
